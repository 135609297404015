var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.formData && _vm.formData.agendamentoConfiguracao.strValor)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left"},[_c('span',{staticClass:"form-title"},[_vm._v("Informações Básicas")]),_c('div',{staticClass:"row"},[_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",attrs:{"label":"Dias de atendimento da clínica","name":"arrayDiasAtendimento","items":_vm.bind.arrayDiasAtendimento,"multiple":"","rules":"required|array"},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor.arrayDiasAtendimento
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor, "arrayDiasAtendimento", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor.arrayDiasAtendimento\n          "}}),_c('ed-input-time',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"label":"Início do Expediente","name":"strHorarioAtendimentoInicial","rules":"required"},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor
              .strHorarioAtendimentoInicial
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor
              , "strHorarioAtendimentoInicial", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor\n              .strHorarioAtendimentoInicial\n          "}}),_c('ed-input-time',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"label":"Fim do Expediente","name":"strHorarioAtendimentoFinal","rules":"required"},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor
              .strHorarioAtendimentoFinal
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor
              , "strHorarioAtendimentoFinal", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor\n              .strHorarioAtendimentoFinal\n          "}}),_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"label":"Número Máximo de Agendamento por horário","name":"intNumeroAgendamentoPorHora","items":_vm.bind.arrayNumeroAgendamentoHora},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor
              .intNumeroAgendamentoPorHora
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor
              , "intNumeroAgendamentoPorHora", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor\n              .intNumeroAgendamentoPorHora\n          "}}),_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",attrs:{"label":"Duração médio de cada atendimento","name":"intDuracaoAtendimento","info":"Esse parâmetro define tambem o intervalo de cada agendamento.","items":_vm.bind.arrayDuracaoAtendimento},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor.intDuracaoAtendimento
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor, "intDuracaoAtendimento", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor.intDuracaoAtendimento\n          "}}),_c('ed-input-text',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",attrs:{"label":"Número máximo de dias para agendamento no futuro","name":"intNumeroMaximoDiasFuturo","info":"Esse parâmetro define em quantos dias no futuro é permitido registrar um agendamento.","placeholder":!_vm.formData.agendamentoConfiguracao.strValor
              .intNumeroMaximoDiasFuturo
              ? 'Ilimitado'
              : '',"type":"number"},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor
              .intNumeroMaximoDiasFuturo
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor
              , "intNumeroMaximoDiasFuturo", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor\n              .intNumeroMaximoDiasFuturo\n          "}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",attrs:{"label":"Notificação para os usuários","name":"arrayNotificarUsuario","item-text":"strNome","route":"Cadastro/Usuario","filters":{
            boolBuscarTodos:true
          },"initializeItems":"","multiple":""},model:{value:(_vm.formData.agendamentoConfiguracao.strValor.arrayNotificarUsuario),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor, "arrayNotificarUsuario", $$v)},expression:"formData.agendamentoConfiguracao.strValor.arrayNotificarUsuario"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left"},[_c('span',{staticClass:"form-title"},[_vm._v("Notificações")]),_c('div',{staticClass:"row"},[_c('ed-input-editor',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",attrs:{"label":"Mensagem Padrão para Novos Agendamentos","name":"strMensagemNovoAgendamento","rules":"required","extraPlugins":"edol"},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor
              .strMensagemNovoAgendamento
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor
              , "strMensagemNovoAgendamento", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor\n              .strMensagemNovoAgendamento\n          "}}),_c('ed-input-editor',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",attrs:{"label":"Mensagem Padrão de Atualização de Status do Agendamento","name":"strMensagemConfirmacao","rules":"required","extraPlugins":"edol"},model:{value:(
            _vm.formData.agendamentoConfiguracao.strValor.strMensagemConfirmacao
          ),callback:function ($$v) {_vm.$set(_vm.formData.agendamentoConfiguracao.strValor, "strMensagemConfirmacao", $$v)},expression:"\n            formData.agendamentoConfiguracao.strValor.strMensagemConfirmacao\n          "}})],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }