<template>
  <div>
    <ed-agendamento-config
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>

import EdAgendamentoConfig from "@/components/agendamento/configuracao/editar";
export default {
  name: "pageCadastro",
  props: {},
  components: {
     EdAgendamentoConfig
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

