<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroHorario" width="80%">
      <div slot="title">{{formDataHorario.intId ? 'Editar horário' : 'Inserir novo horário'}}</div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataHorario"
          :handlerSave="salvar"
          disableFixedSave
        >
          <div class="row">
            <ed-input-time
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataHorario.strHorarioInicial"
              name="strHorarioInicial"
              label="Horário Inicial"
              rules="required"
            />

            <ed-input-time
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataHorario.strHorarioFinal"
              name="strHorarioFinal"
              label="Horário Final"
              rules="required"
            />

            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataHorario.strData"
              name="strData"
              label="Data"
              info="Deixe em branco para aplicar o bloqueio para todas as datas"
            />

            <ed-input-text-area
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataHorario.strObservacao"
              name="strObservacao"
              label="Observação"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @delete="onDelete"
      @edit="onEdit"
      :config="{ registerName: 'Inserir novo horário' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputTime,
  EdInputDate,
  EdInputTextArea,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputTime,
    EdInputDate,
    EdInputTextArea,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroHorario: false,
      arrayHeaders: [
        {
          text: "Horário",
          sortable: true,
          value: "strHorario",
        },
        {
          text: "Data",
          sortable: true,
          value: "strData",
        },
        {
          text: "Observações",
          sortable: true,
          value: "strObservacao",
        },
      ],
      arrayRows: [],
      formDataHorario: {
        intId: 0,
        tipo_email: null,
        strEmail: null,
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (
        !this.formData.agendamentoConfiguracao.strValor.arrayHorariosBloqueados
      ) {
        this.formData.agendamentoConfiguracao.strValor.arrayHorariosBloqueados =
          [];
      }

      let arrayRows = [];
      this.formData.agendamentoConfiguracao.strValor.arrayHorariosBloqueados.forEach(
        (objHorarioBloqueado) => {
          let item = {
            intId: objHorarioBloqueado.intId,
            strHorario:
              objHorarioBloqueado.strHorarioInicial +
              " - " +
              objHorarioBloqueado.strHorarioFinal,
            strData: objHorarioBloqueado.strData
              ? objHorarioBloqueado.strData
              : "Todos",
            strObservacao: objHorarioBloqueado.strObservacao,
            _item: objHorarioBloqueado,
          };

          arrayRows.push(item);
        }
      );

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroHorario = !this.boolExibirCadastroHorario;

      this.formDataHorario = {
        intId: 0,
        strHorarioInicial: null,
        strHorarioFinal: null,
        strData: null,
        strObservacao: null,
      };
    },

    onEdit(data) {
      this.boolExibirCadastroHorario = !this.boolExibirCadastroHorario;
      this.formDataHorario = Object.assign(data, {});
    },

    onDelete(data) {
      let self = this;

      data.forEach((intId) => {
        let indexCadastroEmail = self.getIndexByIntId(intId);

        if (indexCadastroEmail != -1) {
          self.formData.agendamentoConfiguracao.strValor.arrayHorariosBloqueados.splice(
            indexCadastroEmail,
            1
          );
        }
      });

      this.initialize();
    },

    getIndexByIntId(intId) {
      return this.formData.agendamentoConfiguracao.strValor.arrayHorariosBloqueados.findIndex(
        (c) => c.intId == intId
      );
    },

    salvar() {
      if (this.formDataHorario.intId) {
        let indexCadastroEmail = this.getIndexByIntId(this.formDataHorario.intId);

        this.formData.agendamentoConfiguracao.strValor.arrayHorariosBloqueados[indexCadastroEmail] = Object.assign(this.formDataHorario,{})
      } else {
        this.formData.agendamentoConfiguracao.strValor.arrayHorariosBloqueados.push(
          {
            intId:
              this.formData.agendamentoConfiguracao.strValor
                .arrayHorariosBloqueados.length + 1,
            strHorarioInicial: this.formDataHorario.strHorarioInicial,
            strData: this.formDataHorario.strData,
            strHorarioFinal: this.formDataHorario.strHorarioFinal,
            strObservacao: this.formDataHorario.strObservacao,
          }
        );
      }

      this.boolExibirCadastroHorario = !this.boolExibirCadastroHorario;
      this.initialize();
    },
  },
};
</script>
