<template>
  <div v-if="formData && formData.agendamentoConfiguracao.strValor">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Básicas</span>

        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            label="Dias de atendimento da clínica"
            name="arrayDiasAtendimento"
            v-model="
              formData.agendamentoConfiguracao.strValor.arrayDiasAtendimento
            "
            :items="bind.arrayDiasAtendimento"
            multiple
            rules="required|array"
          />

          <ed-input-time
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            label="Início do Expediente"
            name="strHorarioAtendimentoInicial"
            v-model="
              formData.agendamentoConfiguracao.strValor
                .strHorarioAtendimentoInicial
            "
            rules="required"
          />

          <ed-input-time
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            label="Fim do Expediente"
            name="strHorarioAtendimentoFinal"
            v-model="
              formData.agendamentoConfiguracao.strValor
                .strHorarioAtendimentoFinal
            "
            rules="required"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            label="Número Máximo de Agendamento por horário"
            name="intNumeroAgendamentoPorHora"
            v-model="
              formData.agendamentoConfiguracao.strValor
                .intNumeroAgendamentoPorHora
            "
            :items="bind.arrayNumeroAgendamentoHora"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            label="Duração médio de cada atendimento"
            name="intDuracaoAtendimento"
            info="Esse parâmetro define tambem o intervalo de cada agendamento."
            v-model="
              formData.agendamentoConfiguracao.strValor.intDuracaoAtendimento
            "
            :items="bind.arrayDuracaoAtendimento"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            label="Número máximo de dias para agendamento no futuro"
            name="intNumeroMaximoDiasFuturo"
            info="Esse parâmetro define em quantos dias no futuro é permitido registrar um agendamento."
            v-model="
              formData.agendamentoConfiguracao.strValor
                .intNumeroMaximoDiasFuturo
            "
            :placeholder="
              !formData.agendamentoConfiguracao.strValor
                .intNumeroMaximoDiasFuturo
                ? 'Ilimitado'
                : ''
            "
            type="number"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.agendamentoConfiguracao.strValor.arrayNotificarUsuario"
            label="Notificação para os usuários"
            name="arrayNotificarUsuario"
            item-text="strNome"
            route="Cadastro/Usuario"
            :filters="{
              boolBuscarTodos:true
            }"
            initializeItems
            multiple
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <span class="form-title">Notificações</span>

        <div class="row">
          <ed-input-editor
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            label="Mensagem Padrão para Novos Agendamentos"
            name="strMensagemNovoAgendamento"
            v-model="
              formData.agendamentoConfiguracao.strValor
                .strMensagemNovoAgendamento
            "
            rules="required"
            extraPlugins="edol"
          />

          <ed-input-editor
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            label="Mensagem Padrão de Atualização de Status do Agendamento"
            name="strMensagemConfirmacao"
            v-model="
              formData.agendamentoConfiguracao.strValor.strMensagemConfirmacao
            "
            rules="required"
            extraPlugins="edol"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputSelect,
  EdInputDate,
  EdInputTime,
  EdInputText,
  EdInputEditor,
  EdInputAutoComplete,
  EdButton,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdInputSelect,
    EdInputDate,
    EdInputTime,
    EdInputText,
    EdInputEditor,
    EdInputAutoComplete,
    EdButton,
  },
  inject: ["bind"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
