<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroData" width="80%">
      <div slot="title">
        {{ formDataHorario.intId ? "Editar nova data" : "Inserir nova data" }}
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataHorario"
          :handlerSave="salvar"
          disableFixedSave
        >
          <div class="row">
            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataHorario.strData"
              name="strData"
              label="Data"
              rules="required"
            />

            <ed-input-text-area
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataHorario.strObservacao"
              name="strObservacao"
              label="Observação"
            />

            <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataHorario.boolRepetirAnualmente"
              name="boolRepetirAnualmente"
              label="Repetir data anualmente"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
      :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @delete="onDelete"
      @edit="onEdit"
      :config="{ registerName: 'Inserir novo data' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputDate,
  EdInputSwitch,
  EdInputTextArea,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputDate,
    EdInputSwitch,
    EdInputTextArea,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroData: false,
      arrayHeaders: [
        {
          text: "Data",
          sortable: true,
          value: "strData",
        },
        {
          text: "Repetir anualmente",
          sortable: true,
          value: "boolRepetirAnualmente",
        },
        {
          text: "Observações",
          sortable: true,
          value: "strObservacao",
        },
      ],
      arrayRows: [],
      formDataHorario: {
        intId: 0,
        tipo_email: null,
        strEmail: null,
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (
        !this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas
      ) {
        this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas =
          [];
      }

      let arrayRows = [];
      this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas.forEach(
        (objDataBloqueado) => {
          let item = {
            intId: objDataBloqueado.intId,
            strData: objDataBloqueado.strData,
            strObservacao: objDataBloqueado.strObservacao,
            boolRepetirAnualmente: this.$utilidade.parseBoolean(
              objDataBloqueado.boolRepetirAnualmente
            ),
            _item: objDataBloqueado,
          };

          arrayRows.push(item);
        }
      );

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroData = !this.boolExibirCadastroData;

      this.formDataHorario = {
        intId: 0,
        strData: null,
        boolRepetirAnualmente: 0,
        strObservacao: null,
      };
    },

    onEdit(data) {
      this.boolExibirCadastroData = !this.boolExibirCadastroData;

      this.formDataHorario = Object.assign(data, {});
    },

    onDelete(data) {
      let self = this;

      data.forEach((intId) => {
        let indexCadastroEmail = self.getIndexByIntId(intId);

        if (indexCadastroEmail != -1) {
          self.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas.splice(
            indexCadastroEmail,
            1
          );
        }
      });

      this.initialize();
    },

    getIndexByIntId(intId) {
      return this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas.findIndex(
        (c) => c.intId == intId
      );
    },

    salvar() {
      if (
        !this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas
      ) {
        this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas =
          [];
      }

      if (this.formDataHorario.intId) {
        let indexCadastroEmail = this.getIndexByIntId(
          this.formDataHorario.intId
        );

        this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas[
          indexCadastroEmail
        ] = Object.assign(this.formDataHorario, {});
      } else {
        this.formData.agendamentoConfiguracao.strValor.arrayDatasBloqueadas.push(
          {
            intId:
              this.formData.agendamentoConfiguracao.strValor
                .arrayDatasBloqueadas.length + 1,
            strData: this.formDataHorario.strData,
            boolRepetirAnualmente: this.formDataHorario.boolRepetirAnualmente,
            strObservacao: this.formDataHorario.strObservacao,
          }
        );
      }

      this.boolExibirCadastroData = !this.boolExibirCadastroData;
      this.initialize();
    },
  },
};
</script>
